import React from "react";

import WPDLogo from "src/assets/images/testimonials/western-power-distribution-logo.png";
import IntervalFilmsLogo from "src/assets/images/testimonials/interval-films-logo.png";
import AdeliLogo from "src/assets/images/testimonials/adelie-logo.png";
import SociusLogo from "src/assets/images/testimonials/socius-logo.png";
import WasteAccountantLogo from "src/assets/images/testimonials/waste-accountant-logo.png";

import TestimonialCarousel from "./testimonial-carousel";

const testimonials = [
  {
    company_logo: WPDLogo,
    company: "Western Power Distribution",
    name: "Emma Vincent",
    role: "Marketing Manager",
    testimonial:
      "In launching a new App, we were keen to get involved with a local company that understood what we wanted and could work alongside our existing internal systems. The project was a lot more technical than we had originally anticipated but thanks to Gravitywell we have been able to provide an extremely useful customer service tool which has been well received."
  },
  {
    company_logo: IntervalFilmsLogo,
    company: "Interval Films",
    name: "Ollie Larkin",
    role: "Founder",
    testimonial:
      "They have not only provided us with a highly aesthetic, very technical and above all functional solution, they had a huge amount of creative input into our web app, meaning it’s not just catching up to or being on par with industry, it’s become the very best online experience for interacting with time-lapse content, bar none."
  },

  {
    company_logo: AdeliLogo,
    company: "Adelie",
    name: "Matthew Arnott",
    role: "Head of Business Systems & Service Delivery",
    testimonial:
      "Gravitywell managed to understand our existing complex business needs and to anticipate future ones. They delivered the right technology for the project but also brought a design and UX consideration that is rarely seen at enterprise-level. We're very happy with their impressive effort, pro-active attitude and general responsiveness so we expect to continue the positive relationship in the future."
  },
  {
    company_logo: SociusLogo,
    company: "Socius",
    name: "Simon Lloyd",
    role: "CEO",
    testimonial:
      "I was impressed with how Gravitywell threw themselves into the project with great passion and commitment. Their approachability and down-to-earth style was much appreciated as working on a digital start-up has considerable challenges. They found creative solutions and always pushed for the best results. I hope to work with them again soon."
  },
  {
    company_logo: WasteAccountantLogo,
    company: "WasteAccountant.com",
    name: "Luke Bridges",
    role: "CEO",
    testimonial:
      "Gravitywell are a great company to work with. They are flexible, helpful and trustworthy. We have been most impressed with how professional they are. It is due to this approach that we can now deliver our ambitious projects in a shorter time frame and at a lower cost."
  }
];

/**
 * Testimonials
 */
const Testimonials: React.FC = () => (
  <>
    <h2 className="off-screen">
      Testimonials
    </h2>

    <TestimonialCarousel content={testimonials} />
  </>
);

export default Testimonials;
